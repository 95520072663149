import { lazy } from "react";
import { app, fullscreen } from "~/helpers/routes";
import { paths } from "~/router/paths";

const HomePage = lazy(() => import("~/pages/home"));
const OrdersPage = lazy(() => import("~/pages/orders"));
const ImportListPage = lazy(() => import("~/pages/import-list"));
const LiveProductsPage = lazy(() => import("~/pages/live-products"));
const BrandedInvoice = lazy(() => import("~/pages/branded-invoice"));
const CancelationPage = lazy(() => import("~/pages/cancel"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(HomePage),
  },
  {
    path: paths.app.orders,
    element: app(OrdersPage, paths.app.orders),
  },
  {
    path: paths.app.sampleOrders,
    element: app(OrdersPage, paths.app.sampleOrders),
  },
  {
    path: paths.app.importList,
    element: app(ImportListPage),
  },
  {
    path: paths.app.liveProducts,
    element: app(LiveProductsPage),
  },
  {
    path: paths.app.brandedInvoice,
    element: app(BrandedInvoice)
  },
  {
    path: paths.app.cancel,
    element: fullscreen(CancelationPage),
  }
];
