import { Dispatch, SetStateAction } from "react";
import { IOnboardingChoices } from "~/types/account";

interface Step {
  titleKey: string;
  component: (props: {
    onboardingChoices: IOnboardingChoices | undefined;
    setOnboardingChoices: Dispatch<
      SetStateAction<IOnboardingChoices | undefined>
    >;
  }) => JSX.Element;
}

export const steps: Step[] = [];
